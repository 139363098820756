  table, td, th {  
    border: 1px solid #999;
    border-collapse: collapse;
    width: 50%;
    text-align: center;
  }
  
  th, td {
    padding: 15px;
  }

  tr:hover {
    background-color: #aaa;
  }

  tr:nth-child(even) {
    background-color: #ddd;
  }
  